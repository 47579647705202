import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'

export default function Slider() {

    const { t } = useTranslation();

    return(

        <section id="mainSlider">

            <div id="carouselSlider" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselSlider" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselSlider" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselSlider" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#carouselSlider" data-bs-slide-to="3" aria-label="Slide 4"></button>
                </div>
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <StaticImage
                            src="../images/slider/slider1.jpg"
                            width={1800}
                            height={600}
                            formats={["auto", "webp", "avif"]}
                            alt={t("Residential")}
                        />
                        <div className="carousel-caption">
                            <h2><Trans>Residential</Trans></h2>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <StaticImage
                            src="../images/slider/slider2.jpg"
                            width={1800}
                            height={600}
                            quality={90}
                            formats={["auto", "webp", "avif"]}
                            alt={t("Remodels")}
                        />
                        <div className="carousel-caption">
                            <h2><Trans>Remodels</Trans></h2>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <StaticImage
                            src="../images/slider/slider3.jpg"
                            width={1800}
                            height={600}
                            quality={90}
                            formats={["auto", "webp", "avif"]}
                            alt={t("Kitchens")}
                        />
                        <div className="carousel-caption">
                            <h2><Trans>Kitchens</Trans></h2>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <StaticImage
                            src="../images/slider/slider4.jpg"
                            width={1800}
                            height={600}
                            quality={90}
                            formats={["auto", "webp", "avif"]}
                            alt={t("Comercial")}
                        />
                        <div className="carousel-caption">
                            <h2><Trans>Comercial</Trans></h2>
                        </div>
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselSlider" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselSlider" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>

        </section>

    )

}