import * as React from "react"
import { Link, Trans } from 'gatsby-plugin-react-i18next'

export default function AboutUs() {

    return(

        <section id="mainAboutUs" className="my-5 py-5 bg-light">

            <div className="container">

                <div className="row">

                    <div className="col-12 text-center mb-4 py-lg-5">

                        <h3 className="h2"><Trans>ABOUT US</Trans></h3>
                        <p style={{maxWidth:`800px`,margin:`auto`,}} className="py-3 mb-4"><Trans>About us home Text</Trans></p>
                        <Link to="/about-us/" className="btn btn-dark"><Trans>ABOUT US</Trans></Link>

                    </div>

                </div>

            </div>

        </section>

    )

}